import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Loader, Table } from "components";
import ModalService from "components/modal/ModalService";
import DeleteTotalDevices from "./DeleteTotalDevices";
import { useRegisteredDevices } from "./hooks/useRegisteredDevices";

const ListDevices = () => {
  const { devices, isLoading } = useRegisteredDevices();
  const [rowCount, setRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [warrentydData, setWarrentydData] = useState([]);

  useEffect(() => {
    setRowCount(devices?.warranty?.length);
    getAllWarrentyData(devices)
  }, [devices]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);

  const options = {
    customToolbar: () => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            marginBottom: "16px",
            fontSize: "18px",
            marginLeft: "-58px",
          }}
        >
          Total Row Count: <b>{devices?.warranty?.length}</b>
        </div>
      </div>
    ),
    onTableChange: (action, tableState) => {
      if (action === "filterChange" || action === "search") {
        // Update the filtered data when filters change
        setFilteredData(tableState?.displayData?.map((row) => row?.data));
      }
    },
  };

  const DeleteTotalDevice = (id, name) => {
    ModalService.open(DeleteTotalDevices, {
      id,
      name,
    });
  };

  function getAllWarrentyData(devices) {
    if (!devices?.warranty) {
      return [];
    }
    const tempdata = devices.warranty.map((val) => ({
      name: val?.model?.[0]?.modelName || "-", // Safe navigation for `model`
      imei: val?.imei || "-",
      _id: val?._id || "-",
      purchase_Date: val?.purchaseDate || "-",
      warranty_Date: val?.warrantyDate || "-",
      active: val?.active ?? false, // Default to false if undefined
      customername: `${val?.fname || ""} ${val?.lname || ""}`.trim(),
      customercontact: val?.phone || "-",
      customeremail: val?.email || "-",
      purchase_location: val?.location_dealer || val?.location_warehouse || "-"
    }));
  
    setWarrentydData(tempdata);
  }
  

  const columns = [
    {
      name: "_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Product Name",
      name: "name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "PRODUCT IMEI",
      name: "imei",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "PURCHASE LOCATION",
      name: "purchase_location",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value}</>;
        },
      },
    },
    {
      label: "PURCHASE DATE",
      name: "purchase_Date",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
    {
      label: "WARRANTY DATE",
      name: "warranty_Date",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
    {
      label: "STATUS",
      name: "active",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>
                {value && (
                  <>
                    <span className="badge badge-mark border-success mr-1" />{" "}
                    Active
                  </>
                )}
                {!value && (
                  <>
                    <span className="badge badge-mark border-danger mr-1" />{" "}
                    Expired
                  </>
                )}
              </div>
            </>
          );
        },
      },
    },
    {
      label: "CUSTOMER NAME",
      name: "customername",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "CUSTOMER CONTACT",
      name: "customercontact",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "CUSTOMER EMAIL",
      name: "customeremail",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>
                {/* <Link
									style={{ fontWeight: 600 }}
									to={`/inventory/product-configuration/${tableMeta.rowData[1]}`}
									className='letter-icon-title'
								> */}
                {value}
                {/* </Link> */}
              </div>
            </>
          );
        },
      },
    },
    {
      label: "ACTION",
      name: "date",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="list-icons">
              <div className="list-icons-item dropdown">
                <button
                  className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                  data-toggle="dropdown"
                >
                  <i className="icon-menu7" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  {/* <a
					  href
					  className="dropdown-item"
					  onClick={() => viewCoupon(tableMeta.rowData[0])}
					>
					  <i className="icon-file-eye" /> View
					</a> */}
                  {/* <a
					  href
					  className="dropdown-item"
					  onClick={() => editCoupon(tableMeta.rowData[0])}
					>
					  <i className="icon-pencil" /> Edit
					</a> */}
                  <a
                    href
                    className="dropdown-item"
                    onClick={(e) =>
                      DeleteTotalDevice(
                        tableMeta.rowData[0],
                        tableMeta.rowData[1]
                      )
                    }
                  >
                    <i className="icon-trash" /> Delete
                  </a>
                  
                </div>
              </div>
            </div>
          );
        },
      },
    },
  ];
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Table
            title={"Warranty"}
            data={warrentydData}
            columns={columns}
            option={options}
          />
        </>
      )}
    </>
  );
};

export default ListDevices;
